import cx from 'classnames';
import { ArrowDownIconM } from '@carvertical/icons/react';
import styles from './ScrollIndicator.module.scss';

type ScrollIndicatorProps = {
  className?: string;
};

const ScrollIndicator = ({ className }: ScrollIndicatorProps) => (
  <div className={cx(styles.root, className)}>
    <ArrowDownIconM className={styles.icon} />
  </div>
);

export { ScrollIndicator };
